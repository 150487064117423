import React from 'react'
import SubscribeForm from '../components/subscribe-form'
import styles from './subscribe.module.scss'
import Layout from '../components/layout'

const SubscribePage = () => {
    return (
        <Layout>
            <div className={styles.container}>                
                <div className={styles.leftContainer}>
                    <h1 className={styles.secondaryHeading}>Mindful Musings</h1>
                    <p className={styles.secondaryText}>A newsletter with thoughts, insights reflections. Join me!</p>
                </div>
                <div className={styles.rightContainer}>            
                    <SubscribeForm />
                </div>
            
            </div>
        </Layout>
    );    
};

export default SubscribePage;