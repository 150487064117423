import React from 'react'
import styles from './footer.module.scss'
import SocialIcons from './social-icons'
import {Link} from 'gatsby'

const Footer = () => {
    return (
        <footer className={styles.siteFooter}>
            <div className={styles.footerLeft}>
                <ul className={styles.nav}>
                    <li><Link className={styles.navLink} to="/">HOME</Link></li>
                    <li><Link className={styles.navLink} to="/about">ABOUT</Link></li>
                    <li><Link className={styles.navLink} to="/faq">FAQ</Link></li>
                </ul>
            </div>
            <div className={styles.footerRight}>
                <SocialIcons iconColor="#FFFFFF" iconSize="48"/> 
            </div>            
        </footer>
    )
}

export default Footer;