import React from "react"
import styles from './subscribe-form.module.scss'

const SubscribeForm = () => {

  return (
    <div class={"fd-ef-" + process.env.GATSBY_FLODESK_ID}>
      <div class="ff__root">
        <div class="ff__container">
          <form action="https://form.flodesk.com/submit" method="post" data-form="fdv2" className={styles.formContainer}>
            <div class="ff__fields">
              <input type="text" name="name" value="" className={styles.hiddenInput}/>
              <input type="hidden" name="submitToken" value={process.env.GATSBY_FLODESK_SUBMIT_TOKEN} />
              <div class="ff__grid">

              <div class="ff__cell">
                  <div class="fd-form-group">
                    <input class="fd-form-control ff__control" type="text" name="firstName" placeholder="First name"/>
                  </div>
                </div>

                <div class="ff__cell">
                  <div class="fd-form-group">
                    <input class="fd-form-control ff__control" type="text" name="lastName" placeholder="Last name"/>
                  </div>
                </div>

                <div class="ff__cell">
                  <div class="fd-form-group">
                    <input class="fd-form-control ff__control" type="text" name="email" placeholder="Email address"/>
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <button type="submit" data-form-el="submit" className={styles.submitButton}>
                    <span>Subscribe</span>
                  </button>
                </div>
              </div>
            </div>   
          </form>
        </div>
      </div>      
    </div>
  )
}

export default SubscribeForm