import React from 'react'
import {Youtube, Instagram, ShoppingBag} from 'react-feather'
import styles from './social-icons.module.scss'
import {OutboundLink} from 'gatsby-plugin-google-analytics'

const SocialIcons = ({iconColor, iconSize}) => {

    return (
        <div className={styles.iconsContainer}>
              <OutboundLink href="https://www.instagram.com/reflectwithraksha/" aria-label="Instagram" target="_blank" rel="noreferrer" className={styles.icon}>
                <Instagram size={iconSize} color={iconColor}/></OutboundLink>
              <OutboundLink href="https://www.youtube.com/reflectwithraksha" aria-label="Youtube" target="_blank" rel="noreferrer" className={styles.icon}>
                <Youtube size={iconSize} color={iconColor}/></OutboundLink>
              {/* <OutboundLink href="https://www.etsy.com/uk/shop/ReflectwithRaksha" aria-label="Etsy" target="_blank" rel="noreferrer" className={styles.icon}>
                <ShoppingBag size={iconSize} color={iconColor}/></OutboundLink>           */}
        </div>
    )
}

export default SocialIcons;