import React from 'react'
import {Link} from 'gatsby'
import '../styles/site.scss'
import layoutStyles from './layout.module.scss'
import Footer from './footer'

const Layout = (props) => {
    const [toggleNav, setToggleNav] = React.useState(false)
    
    return (
        <div className={`${layoutStyles.siteWrapper} ${toggleNav ? `${layoutStyles.siteHeadOpen}` : ``}`}>
            <header className={layoutStyles.siteHead}>
                <div className={layoutStyles.siteHeadContainer}>
                    <button
                        className={layoutStyles.navBurger}                                            
                        onClick={() => setToggleNav(!toggleNav)}
                        aria-label="nav-menu"
                    >
                        <div
                            className={`${layoutStyles.hamburger} ${layoutStyles.hamburgerCollapse}`}                            
                            role="button"
                            aria-label="nav-menu"
                        >
                            <div className={layoutStyles.hamburgerBox}>
                                <div className={layoutStyles.hamburgerInner} />
                            </div>
                        </div>
                    </button>
                    <div className={layoutStyles.siteHeadLogoContainer}>
                        <Link className={layoutStyles.siteHeadLogo} to="/">Reflect with Raksha</Link>
                    </div>
                    <nav className={layoutStyles.siteHeadLeft}>
                        <ul className={layoutStyles.nav}>
                            <li><Link className={layoutStyles.navLink} activeClassName={layoutStyles.activeNavLink} to="/">HOME</Link></li>                            
                            <li><Link className={layoutStyles.navLink} activeClassName={layoutStyles.activeNavLink} to="/about">ABOUT</Link></li>
                            <li><Link className={layoutStyles.navLink} activeClassName={layoutStyles.activeNavLink} to="/faq">FAQ</Link></li>
                            <li><Link className={layoutStyles.navLink} activeClassName={layoutStyles.activeNavLink} to="/blog">BLOG</Link></li>                            
                            <li><Link className={layoutStyles.navLink} activeClassName={layoutStyles.activeNavLink} to="/inspiration">INSPIRATION</Link></li>
                        </ul>
                    </nav>                    
                </div>  
            </header> 
            <div className={layoutStyles.contentWrapper}>  
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout;